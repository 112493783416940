import PropTypes from "prop-types";

import styles from "@platformAssets/components/Textarea.module.scss";

export const Textarea = ({
  animatePlaceholder,
  customClass,
  error = false,
  errorText = null,
  handleChange,
  limit,
  name,
  onBlur = null,
  placeholder,
  rows,
  textareaId,
  value,
}) => {
  return (
    <div
      className={`${styles.textareaWrapper} ${
        animatePlaceholder ? styles.textareaAnimated : ""
      } ${error ? styles.textareaError : ""}`}
    >
      <textarea
        name={name}
        onChange={(e) => handleChange(e.target.value)}
        value={value}
        rows={rows ? rows : 3}
        placeholder={placeholder}
        id={textareaId ? textareaId : null}
        className={customClass}
        onBlur={() => {
          if (typeof onBlur === "function") {
            onBlur();
          }
        }}
      />
      {animatePlaceholder && (
        <label
          htmlFor={textareaId ? textareaId : null}
          className={styles.animatedPlaceholder}
        >
          {placeholder}
        </label>
      )}
      <p className={styles.errorText}>{errorText}</p>

      {limit && (
        <p
          className={`${styles.errorMaxLength} ${
            value.trim().length > limit ? styles.errorText : ""
          }`}
        >
          {value.trim().length}/{limit}
        </p>
      )}
    </div>
  );
};

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  rows: PropTypes.number,
  textareaId: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  animatePlaceholder: PropTypes.bool,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  customClass: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  onBlur: PropTypes.func,
};
